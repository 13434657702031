/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  modal: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 0,
  },
  switchBase: {
    marginLeft: 0,
    marginBottom: 0,
  },
  label: {
    color: theme.palette.primary.main,
  },
  button: {
    padding: '8px 25px',
    marginTop: '20px',
    borderRadius: '50px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      right: '15px',
      marginTop: 0,
    },
  },
}));

const CookieManagementModal = ({ cookie }) => {
  const classes = useStyles();
  const storedInput = localStorage.getItem('allow-analytics');

  const [allow, setAllow] = useState(JSON.parse(storedInput) || false);

  const handleSwitch = (e) => {
    setAllow(e.target.checked);
  };

  const handleSubmit = () => {
    localStorage.setItem('allow-analytics', allow);
    cookie(allow);
  };

  return (
    <div className={classes.modal} data-testid="cookie-modal">
      <Typography variant="h3">Cookie Settings</Typography>
      <Typography variant="h4">Analytical cookies</Typography>
      <Typography variant="body1">
        Analytical cookies help us improve our website by collecting and
        reporting information on its usage.
      </Typography>
      <form
        className={classes.form}
        onSubmit={handleSubmit}
        data-testid="cookie-form"
      >
        <FormControlLabel
          control={
            <Switch
              className={classes.switchBase}
              checked={allow}
              onChange={handleSwitch}
              name="Allow"
              inputProps={{ role: 'switch' }}
            />
          }
          label="Allow"
        />
        <Button
          type="submit"
          data-testid="saveBtn"
          variant="contained"
          className={classes.button}
          classes={{ root: classes.button, label: classes.label }}
        >
          Save settings
        </Button>
      </form>
    </div>
  );
};

CookieManagementModal.propTypes = {
  cookie: PropTypes.func,
};

CookieManagementModal.defaultProps = {
  cookie: () => {},
};

export default CookieManagementModal;
