export const dropdowns = {
  supportServices: {
    title: 'Support Services',
    items: [
      {
        title: 'Emotional Support',
        slug: '/support-service/emotional-support',
      },
      {
        title: 'Medical Services',
        slug: '/support-service/medical-services',
      },
      {
        title: 'Health and Wellbeing',
        slug: '/support-service/health-and-wellbeing',
      },
      {
        title: 'Practical Concerns',
        slug: '/support-service/practical-concerns',
      },
    ],
  },
  about: {
    title: 'About',
    items: [
      {
        title: 'Cancer Care Map',
        slug: '/about/cancer-care-map',
      },
      {
        title: 'Our Team',
        slug: '/about/our-team',
      },
    ],
  },
};

export const navItems = [
  { title: 'What is Cancer', slug: '/what-is-cancer' },
  { title: 'Blog', slug: '/articles' },
  { title: 'Add a Service', slug: '/add-service' },
];
