/* eslint-disable react/require-default-props */
import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, MenuItem, MenuList } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import KeyCode from '../../../utils/enums/KeyCode';

const useStyles = makeStyles((theme) => ({
  submenu_parent_item: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    '&:focus-visible': {
      outline: '-webkit-focus-ring-color auto 1px',
      outlineOffset: '-1px',
    },
    '&:hover': {
      backgroundColor: theme.palette.coreWhite,
    },
  },
  submenu_list: {
    width: '100%',
    backgroundColor: theme.palette.coreWhite,
    padding: '0px',
  },
  submenu_list_hidden: {
    display: 'none',
  },
  submenu_toggle: {
    boxSizing: 'border-box',
    width: '100%',
    height: '42px',
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.coreWhite,
    '&:focus': {
      outline: 'none',
    },
    '&:focus-visible': {
      outline: '-webkit-focus-ring-color auto 1px',
      outlineOffset: '-1px',
    },
    '&:hover': {
      backgroundColor: theme.palette.coreYellow,
    },
  },
  submenu_item: {
    boxSizing: 'content-box',
    padding: '6px 26px',
    height: '30px',
    '&:focus-visible': {
      backgroundColor: theme.palette.coreWhite,
      outline: '-webkit-focus-ring-color auto 1px',
      outlineOffset: '-1px',
    },
  },
}));

export default function MobileSubMenu({
  title,
  menuItems,
  mainMenuRef,
  mainMenuIndex,
  submenuName,
  handleSubmenus,
  focusSubmenu,
  setOpen,
}) {
  const classes = useStyles();

  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [submenuIndex, setSubmenuIndex] = useState(0);

  const submenuParentItemRef = useRef(null);
  const submenuListRef = useRef(null);
  const submenuToggleRef = useRef(null);

  useEffect(() => {
    // handle focus on initial render
    // should focus parent menu item when this component is first rendered
    // effectively does what autoFocusMenu prop does with conventional MenuList
    if (focusSubmenu) submenuParentItemRef.current.focus();
  }, []);

  const toggleSubmenu = () => {
    // toggles submenu open/close
    setSubmenuOpen((prevOpenSub) => !prevOpenSub);
  };

  useEffect(() => {
    // closes submenu when isOpen is false
    if (setOpen === false) {
      setSubmenuOpen(false);
    }
  }, [setOpen]);

  const prevOpenSub = useRef(submenuOpen);

  useEffect(() => {
    if (
      setOpen === true &&
      prevOpenSub.current === true &&
      submenuOpen === false
    ) {
      // when submenu is closed but is not forced closed by main menu, shift focus to the toggle button
      submenuParentItemRef.current.focus();
    }

    prevOpenSub.current = submenuOpen;

    if (submenuOpen) {
      // if submenu is open, call parent function to handle relationship between multiple submenus
      handleSubmenus(submenuName);
    }
  }, [submenuOpen]);

  const handleListKeyDown = (event) => {
    if (event.keyCode !== KeyCode.SPACE) {
      // stop event propagation to allow correct keyboard navigation over submenu
      event.stopPropagation();
    }
  };

  const handleKeyDownSubmenuItem = (event) => {
    switch (event.keyCode) {
      case KeyCode.DOWN:
        if (submenuIndex === menuItems.length - 1) {
          // if focus was on last submenu item, move focus to next item on main menu
          event.stopPropagation();
          mainMenuRef.current.children[mainMenuIndex + 1].focus();
          setSubmenuIndex(0);
        } else {
          setSubmenuIndex((prevSubmenuIndex) => prevSubmenuIndex + 1);
        }
        break;
      case KeyCode.UP:
        if (submenuIndex === 0) {
          // if focus was on first submenu item, move focus to submenu toggle
          event.stopPropagation();
          submenuToggleRef.current.focus();
          setSubmenuIndex(0);
        } else {
          setSubmenuIndex((prevSubmenuIndex) => prevSubmenuIndex - 1);
        }
        break;
      case KeyCode.ENTER:
        if (event.keyCode === KeyCode.ENTER) {
          // if enter is pressed, select current item
          event.stopPropagation();
        }
        break;
      default:
    }
  };

  const handleSubmenuParentItemFocus = () => {
    if (
      document.activeElement === submenuParentItemRef.current &&
      submenuOpen
    ) {
      // when focus first enters the submenu and the sub menu is open, set focus on the toggle button
      submenuToggleRef.current.focus();
      setSubmenuIndex(0);
    }
  };

  const handleToggleKeyDown = (event) => {
    if (event.keyCode === KeyCode.UP) {
      // go to previous item on main menu
      event.stopPropagation();
      if (mainMenuIndex !== 0) {
        mainMenuRef.current.children[mainMenuIndex - 1].focus();
      }
    }

    if (event.keyCode === KeyCode.DOWN) {
      // go to next item on submenu
      event.preventDefault();
      event.stopPropagation();
      submenuListRef.current.children[0].focus();
    }

    if (event.keyCode === KeyCode.SPACE || event.keyCode === KeyCode.ENTER) {
      // stop propagation and prevent default to prevent space taking user half way down the page
      event.stopPropagation();
      event.preventDefault();
      toggleSubmenu();
    }
  };

  const handleSubmenuParentItemKeyDown = (event) => {
    if (event.keyCode === KeyCode.SPACE || event.keyCode === KeyCode.ENTER) {
      // when space is key on submenu parent, click the submenu toggle button
      event.preventDefault();
      submenuToggleRef.current.click();
    }
  };

  return (
    <MenuItem
      className={classes.submenu_parent_item}
      onFocus={handleSubmenuParentItemFocus}
      onKeyDown={handleSubmenuParentItemKeyDown}
      aria-haspopup="true"
      aria-controls={submenuOpen ? 'submenu' : undefined}
      aria-expanded={submenuOpen}
      ref={submenuParentItemRef}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={toggleSubmenu}
        onKeyDown={handleToggleKeyDown}
        className={classes.submenu_toggle}
        aria-expanded={submenuOpen}
        ref={submenuToggleRef}
      >
        {title}
        {submenuOpen ? <ExpandLess /> : <ExpandMore />}
      </div>
      <MenuList
        autoFocusItem={submenuOpen}
        id="submenu"
        onKeyDown={handleListKeyDown}
        ref={submenuListRef}
        className={
          submenuOpen ? classes.submenu_list : classes.submenu_list_hidden
        }
        aria-hidden={!submenuOpen}
        aria-label={`${title} submenu`}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.slug}
            to={item.slug}
            component={Link}
            className={classes.submenu_item}
            onKeyDown={handleKeyDownSubmenuItem}
            role="link"
          >
            {item.title}
          </MenuItem>
        ))}
      </MenuList>
    </MenuItem>
  );
}

MobileSubMenu.propTypes = {
  title: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainMenuRef: PropTypes.objectOf(PropTypes.object),
  focusSubmenu: PropTypes.bool.isRequired,
  mainMenuIndex: PropTypes.number.isRequired,
  submenuName: PropTypes.string.isRequired,
  handleSubmenus: PropTypes.func.isRequired,
  setOpen: PropTypes.bool.isRequired,
};
