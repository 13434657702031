import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@material-ui/core/';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Link from 'gatsby-link';
import { KeyCode } from '../../../utils/enums/KeyCode';

const useStyles = makeStyles((theme) => ({
  dropdown_container: {
    color: theme.palette.coreBlue,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '16px',
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  arrowIcon: {
    color: theme.palette.coreBlue,
    height: 22,
  },
  menuOptions: {
    display: 'block',
  },
  menu_list: {
    padding: '0px',
  },
  item: {
    boxSizing: 'content-box',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    lineHeight: '140%',
    textTransform: 'uppercase',
    minHeight: '40px',
    height: '40px',
  },
  link: {
    textDecoration: 'none',
  },
}));

export default function DesktopMenuDropdown({ title, items }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpenRef = useRef(open);

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = (event) => {
    if (
      event.keyCode === KeyCode.SPACE ||
      event.keyCode === KeyCode.ENTER ||
      event.keyCode === KeyCode.DOWN
    ) {
      event.preventDefault();
      handleOpen();
    }
  };

  const handleMenuKeyDown = (event) => {
    if (event.keyCode === KeyCode.ESC) {
      handleClose(event);
    }
  };

  const handleMenuClick = () => {
    handleOpen();
  };

  useEffect(() => {
    if (prevOpenRef.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpenRef.current = open;
  }, [open]);

  return (
    <>
      <div
        ref={anchorRef}
        className={classes.dropdown_container}
        onKeyDown={handleToggle}
        role="button"
        tabIndex={0}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-expanded={open}
        onClick={handleOpen}
      >
        {title}
        {open ? (
          <ExpandLess className={classes.arrowIcon} />
        ) : (
          <ExpandMore className={classes.arrowIcon} />
        )}
      </div>

      <Popper
        className={classes.menuOptions}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleMenuKeyDown}
                  onClick={handleMenuClick}
                  title="menu-list"
                  className={classes.menu_list}
                >
                  {items.map((item) => (
                    <MenuItem
                      key={item.slug}
                      to={item.slug}
                      component={Link}
                      className={classes.item}
                      role="link"
                      onClick={handleClose}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

DesktopMenuDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
    }),
  ).isRequired,
};
