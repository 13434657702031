/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie-consent';
import CookieBanner from './CookieBanner/CookieBanner';

// eslint-disable-next-line import/no-named-as-default
import Header from './header/header';
import Footer from './footer/footer';
import './layout.css';

const Layout = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openCookieModal = () => {
    setIsModalOpen(true);
    Cookies.remove('gatsby-gdpr-google-analytics');
  };

  const cookie = Cookies.get('gatsby-gdpr-google-analytics');

  return (
    <>
      {!cookie && <CookieBanner isModalOpen={isModalOpen} />}
      <Header />
      <main>{children}</main>
      <Footer isModalOpen={openCookieModal} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
