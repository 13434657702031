import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Button } from '@material-ui/core/';
import { Facebook, Instagram, Twitter } from '@material-ui/icons';
import { Link } from 'gatsby';
import { coreBlue, coreWhite } from '../../themeprovider/ccmTheme/colorPalette';

const useStyles = makeStyles((theme) => ({
  container: {
    color: coreWhite,
    padding: '25px 20px',
    backgroundColor: coreBlue,
    [theme.breakpoints.up('md')]: {
      paddingLeft: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '200px',
    },
  },
  content: {
    [theme.breakpoints.up('xl')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  footer_card: {
    marginBottom: '35px',
    '& img': {
      marginBottom: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
    },
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 20,
    color: theme.palette.coreYellow,
    paddingBottom: 10,
  },
  social_media_icon_container: {
    color: coreWhite,
    width: '100%',
    display: 'flex',
    '& a': {
      color: coreWhite,
    },
  },
  social_media_icon: {
    marginRight: '20px',
    height: '32px',
    width: '32px',
  },
  useful_links: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  link: {
    marginBottom: '5px',
    textDecoration: 'none',
    borderBottom: '2px solid white',
    paddingBottom: '3px',
    marginRight: '26px',
    fontWeight: 'bold',
    fontSize: 18,
    width: 'fit-content',
    color: coreWhite,
  },
  buttonLabel: {
    paddingBottom: '0',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '5px',
    },
  },
  social_link_container: {
    backgroundColor: 'transparent',
    color: coreWhite,
    height: 'initial',
    width: 'initial',
    marginTop: '16px',
    marginRight: '16px',
  },
  textButton: {
    marginBottom: '5px',
    borderBottom: '2px solid white',
    paddingBottom: '8px',
    lineHeight: 0,
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'initial',
    width: 'unset',
    top: 'unset',
    [theme.breakpoints.up('xs')]: {
      position: 'relative',
      top: '5px',
      width: 'fit-content',
    },
    [theme.breakpoints.up('md')]: {
      top: 'unset',
      width: 'unset',
    },
  },
}));

export const Footer = ({ isModalOpen }) => {
  const footerLink = [
    { path: '/contact', text: 'Contact' },
    { path: '/terms-and-conditions', text: 'Terms and conditions' },
    { path: '/privacy-policy', text: 'Privacy policy' },
  ];
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.footer_card}>
          <div className={classes.heading}>Supported by:</div>
          <Typography variant="h4">
            NHS England, support organisations and cancer charities across the
            UK
          </Typography>
        </div>
        <div className={classes.footer_card}>
          <div className={classes.heading}>Useful links: </div>
          <div className={classes.useful_links}>
            {footerLink.map(({ text, path }) => (
              <Link to={path} className={classes.link} key={text}>
                {text}
              </Link>
            ))}
            <Button
              type="button"
              variant="text"
              data-testid="cookie-settings-btn"
              classes={{ label: classes.buttonLabel }}
              className={classes.textButton}
              key="cookie-settings"
              onClick={() => {
                isModalOpen(true);
              }}
            >
              Cookie settings
            </Button>
          </div>
        </div>
        <div className={classes.footer_card}>
          <div className={classes.heading}>Socials:</div>
          <div className={classes.social_media_icon_container}>
            <a
              href="https://www.facebook.com/CancerCareMap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook
                titleAccess="facebook_logo"
                className={classes.social_media_icon}
              />
            </a>
            <a
              href="https://www.instagram.com/cancercaremap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram
                titleAccess="instagram_logo"
                className={classes.social_media_icon}
              />
            </a>
            <a
              href="https://twitter.com/cancercaremap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter
                titleAccess="twitter_logo"
                className={classes.social_media_icon}
              />
            </a>
          </div>
        </div>
        <div className={classes.footer_card}>
          <div className={classes.heading}>Designed and built by:</div>
          <a
            href="https://and.digital/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.footer_and_logo}
              src="/AND_Digital_Logo.png"
              alt="AND_logo"
            />
          </a>
        </div>
      </div>
      <Typography variant="body1">© 2020 Cancer Care Map™</Typography>
    </div>
  );
};

Footer.propTypes = {
  isModalOpen: PropTypes.func,
};

Footer.defaultProps = {
  isModalOpen: null,
};

export default Footer;
