import React from 'react';
import { makeStyles, Button, MenuItem } from '@material-ui/core/';
import Link from 'gatsby-link';
import DesktopMenuDropdown from './DesktopMenuDropdown';
import { dropdowns, navItems } from '../MenuBarData/MenuBarData';

const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      width: '830px',
      fontFamily: 'Open Sans',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& a': {
        textDecoration: 'none',
      },
    },
  },
  nav_item: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      color: theme.palette.coreBlue,
      display: 'flex',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      fontSize: '16px',
      cursor: 'pointer',
      textTransform: 'uppercase',
    },
  },
  button: {
    minWidth: '170px',
    marginLeft: '10px',
    fontSize: '16px',
  },
  navList: {
    listStyleType: 'none',
    display: 'flex',
    margin: '0',
    padding: '0',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  navListItem: {
    display: 'flex',
    margin: '0',
  },
}));

export default function DesktopMenu() {
  const classes = useStyles();

  return (
    <nav className={classes.menu}>
      <ul className={classes.navList}>
        <li className={classes.navListItem} key="supportServices">
          <DesktopMenuDropdown {...dropdowns.supportServices} />
        </li>
        {navItems
          .filter((navItem) => navItem.slug !== '/add-service')
          .map(({ title, slug }) => (
            <MenuItem
              className={classes.nav_item}
              key={slug}
              to={slug}
              component={Link}
              role="link"
            >
              {title}
            </MenuItem>
          ))}
        <li className={classes.navListItem} key="about">
          <DesktopMenuDropdown {...dropdowns.about} />
        </li>
        {navItems
          .filter((navItem) => navItem.slug === '/add-service')
          .map(({ title, slug }) => (
            <MenuItem
              className={classes.nav_item}
              key={slug}
              to={slug}
              component={Link}
              role="link"
            >
              {title}
            </MenuItem>
          ))}

        <li className={classes.navListItem} key="findServices">
          <Link to="/">
            <Button
              id="findServicecs"
              color="primary"
              variant="contained"
              classes={{ root: classes.button }}
            >
              Find Services
            </Button>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
