import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { makeStyles } from '@material-ui/core/';
import Img from 'gatsby-image';
import MobileMenu from './MobileMenu/MobileMenu';
import DesktopMenu from './DesktopMenu/DesktopMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: '0px 0px 5px #888888',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 20px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      padding: '6px 30px',
    },
  },
  imageWrapper: {
    paddingRight: '20px',
    width: '300px',
  },
  logo: {
    width: '100%',
  },
}));

export const Header = () => {
  const data = useStaticQuery(
    graphql`
      query logoQuery {
        allWordpressWpMedia(
          filter: { source_url: { regex: "/cancer-care-map-logos/" } }
        ) {
          edges {
            node {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  const classes = useStyles();
  return (
    <header className={classes.root} data-testid="headerBar">
      <div className={classes.imageWrapper}>
        <Link to="/">
          <Img
            className={classes.logo}
            imgStyle={{
              objectFit: 'contain',
            }}
            fluid={
              data.allWordpressWpMedia.edges[0].node.localFile.childImageSharp
                .fluid
            }
            alt="cancer care map logo"
          />
        </Link>
      </div>
      <MobileMenu />
      <DesktopMenu />
    </header>
  );
};

export default Header;
