import React, { useState, useRef, useEffect } from 'react';
import {
  makeStyles,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
} from '@material-ui/core/';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from 'gatsby';
import { dropdowns, navItems } from '../MenuBarData/MenuBarData';
import { KeyCode } from '../../../utils/enums/KeyCode';
import MobileSubMenu from './MobileSubMenu';

const MENU_BUTTON_TEXT = 'Menu';
const FIND_SERVICES_BUTTON_TEXT = 'Find Services';
const SUBMENU_1_REF = 'submenu1';
const SUBMENU_2_REF = 'submenu2';

const useStyles = makeStyles((theme) => ({
  menu_container: {
    textTransform: 'uppercase',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  menu_dropdown_container: {
    position: 'absolute',
    top: '5px',
    right: '-19px',
    width: '260px',
  },
  '&:focus-visible': {
    outline: '-webkit-focus-ring-color auto 1px',
    outlineOffset: '-1px',
  },
  '&:hover': {
    backgroundColor: theme.palette.coreWhite,
  },
  menu_toggle: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    '& p': {
      margin: '0px',
    },
    cursor: 'pointer',
    '&:focus-visible': {
      outline: '-webkit-focus-ring-color auto 1px',
      outlineOffset: '-1px',
    },
    '&:hover': {
      backgroundColor: theme.palette.coreWhite,
    },
  },
  menu_list: {
    textTransform: 'uppercase',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    padding: '0px',
  },
  menu_item: {
    boxSizing: 'content-box',
    height: '30px',
    '&:focus-visible': {
      backgroundColor: theme.palette.coreWhite,
      outline: '-webkit-focus-ring-color auto 1px',
      outlineOffset: '-1px',
    },
    '&:hover': {
      backgroundColor: theme.palette.coreYellow,
    },
  },
  find_services: {
    height: '55px',
  },
}));

export default function MobileMenu() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [submenu1Open, setSubmenu1Open] = useState(false);
  const [submenu2Open, setSubmenu2Open] = useState(false);

  const anchorRef = useRef(null);
  const menuContainerRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    // close menu when escape key is pressed
    if (event.keyCode === KeyCode.ESC) {
      handleClose();
    }
  }

  const handleToggle = () => {
    // toggle menu open/close
    setOpen((prevOpen) => !prevOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleKeyDown = (event) => {
    if (event.keyCode === KeyCode.SPACE || event.keyCode === KeyCode.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      handleToggle(event);
    }
  };

  const handleSubmenus = (submenuRef) => {
    switch (submenuRef) {
      case SUBMENU_1_REF:
        // when submenu 1 is opened, close submenu 2
        setSubmenu1Open(true);
        setSubmenu2Open(false);
        break;
      case SUBMENU_2_REF:
        // when submenu 2 is opened, close submenu 1
        setSubmenu2Open(true);
        setSubmenu1Open(false);
        break;
      default:
    }
  };

  return (
    <nav className={classes.menu_container}>
      <div
        role="button"
        tabIndex={0}
        ref={anchorRef}
        aria-controls={open ? 'menu-list' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        onKeyDown={handleKeyDown}
        className={classes.menu_toggle}
      >
        <Typography variant="body1">{MENU_BUTTON_TEXT}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 3 }}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center top' }}
            className={classes.menu_dropdown_container}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list"
                  onKeyDown={handleListKeyDown}
                  className={classes.menu_list}
                  ref={menuContainerRef}
                >
                  <MobileSubMenu
                    title={dropdowns.supportServices.title}
                    menuItems={dropdowns.supportServices.items}
                    mainMenuRef={menuContainerRef}
                    mainMenuIndex={0}
                    submenuName={SUBMENU_1_REF}
                    handleSubmenus={handleSubmenus}
                    focusSubmenu={open}
                    setOpen={submenu1Open}
                  />
                  {navItems
                    .filter((navItem) => navItem.slug !== '/add-service')
                    .map(({ title, slug }) => (
                      <MenuItem
                        className={classes.menu_item}
                        key={slug}
                        to={slug}
                        component={Link}
                        role="link"
                      >
                        {title}
                      </MenuItem>
                    ))}
                  <MobileSubMenu
                    title={dropdowns.about.title}
                    menuItems={dropdowns.about.items}
                    mainMenuRef={menuContainerRef}
                    mainMenuIndex={3}
                    submenuName={SUBMENU_2_REF}
                    handleSubmenus={handleSubmenus}
                    focusSubmenu={false}
                    setOpen={submenu2Open}
                  />
                  <MenuItem
                    className={[classes.menu_item, classes.find_services]}
                    component={Link}
                    key="findServices"
                    to="/"
                    style={{ zIndex: 1 }}
                    role="link"
                  >
                    <Button
                      id="findServicecs"
                      color="primary"
                      variant="contained"
                    >
                      {FIND_SERVICES_BUTTON_TEXT}
                    </Button>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </nav>
  );
}
