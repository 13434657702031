/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { makeStyles, Typography } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import { coreNavy, coreWhite } from '../../themeprovider/ccmTheme/colorPalette';
import CookieManagementModal from '../CookieManagementModal/CookieManagementModal';

const useStyles = makeStyles((theme) => ({
  bannerText: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '1.5rem',
    marginBottom: 0,
  },
  textButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    color: coreWhite,
    textDecoration: 'underline',
    textTransform: 'lowercase',
  },
  label: {
    fontSize: '16px',
    position: 'relative',
    bottom: '2px',
  },
  link: {
    color: coreWhite,
  },
  content: {
    position: 'relative',
  },
  container: {
    [theme.breakpoints.up('xs')]: {
      paddingBottom: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '10px 175px',
    },
  },
}));

const CookieBanner = ({ isModalOpen }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleCookie = (val) => {
    Cookies.set('gatsby-gdpr-google-analytics', val);
  };

  return (
    <CookieConsent
      location="top"
      buttonText="OK, I AGREE"
      declineButtonText="Decline"
      style={{
        backgroundColor: coreNavy,
        color: coreWhite,
        position: 'relative',
      }}
      containerClasses={classes.container}
      contentClasses={classes.content}
      buttonStyle={
        open || isModalOpen
          ? { display: 'none' }
          : {
              padding: '15px 24px',
              borderRadius: '50px',
              backgroundColor: coreWhite,
            }
      }
      cookieName="gatsby-gdpr-google-analytics"
      onAccept={() => {
        localStorage.setItem('allow-analytics', true);
        window.location.reload(true);
      }}
      sameSite="strict"
      hideOnAccept
      overlay
    >
      <div>
        <Typography variant="body1" className={classes.bannerText}>
          This site uses cookies to improve your online experience.
        </Typography>
        {open || isModalOpen ? (
          <Typography variant="body1" className={classes.bannerText}>
            You can read our
            {' '}
            <a className={classes.link} href="/privacy-policy">
              privacy policy
            </a>
            {' '}
            for more details.
          </Typography>
        ) : (
          <Typography variant="body1" className={classes.bannerText}>
            You can read our
            {' '}
            <a className={classes.link} href="/privacy-policy">
              privacy policy
            </a>
            {' '}
            for more details or change your
            {' '}
            <Button
              className={classes.textButton}
              classes={{ label: classes.label }}
              variant="text"
              onClick={() => setOpen(true)}
            >
              cookie settings
            </Button>
            .
          </Typography>
        )}
      </div>
      {open || isModalOpen ? (
        <CookieManagementModal cookie={handleCookie} />
      ) : null}
    </CookieConsent>
  );
};

CookieBanner.propTypes = {
  isModalOpen: PropTypes.bool,
};

CookieBanner.defaultProps = {
  isModalOpen: false,
};

export default CookieBanner;
